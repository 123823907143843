import React from 'react';
import './HomePage.scss';

export default function Home() {
  
  return (
    <div>
      <div>Please use the header above to navigate the application.</div>
    </div>
  );
}

